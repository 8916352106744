body {
  background-color: $background !important;
}

img {
  width: 100%;
}

a {
  color: inherit;
}

.text-error {
  color: $red !important;
}

.title-case {
  text-transform: capitalize !important;
}

.bg-ligh-grey {
  background-color: $lightGrey;
}
.bg-ligh-blue {
  background-color: $lightblue;
  color: $white;
}

.bg-blur {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #0000004d;
  backdrop-filter: blur(0.3px);
}

.custom-preloader-container {
  position: fixed;
  text-align: center;
  top: 50%;
  left: 54%;
  padding: 2em 3em;
  border-radius: 1em;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 99;
  background-color: $lightGrey; //rgba(255, 255, 255, 0.5);
}

.box-shadow {
  // border-top: 1px solid $primary;
  padding: 1em;
  // border: 1px solid $grey;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: 0 0 32px 0 #1264780d;
}

.btn {
  text-transform: capitalize !important;

  &.btn-info {
    color: $lightblue !important;
  }
}

.bg-grey {
  background-color: $background;
}

.bg-primary {
  background-color: $primary;
}

.MuiDrawer-root {
  .MuiPaper-root {
    border-right: 0 !important;
    padding: 10px 1.5em;
  }

  .custom-nav-link {
    border-radius: 5px;
    margin-bottom: 1em;

    &.custom-active {
      background-color: $primary !important;
      color: $white !important;

      .sidebar-icon.dark {
        display: none;
      }

      .sidebar-icon.light {
        display: block;
      }
    }
    .sidebar-icon {
      width: 25px;

      &.light {
        display: none;
      }
    }

    .label {
      margin-top: 0.5em;
      font-weight: 600;
    }
  }
}

.flex {
  display: flex;
  gap: 4px;

  &.flex-column {
    flex-direction: column;
  }

  &.flex-row {
    flex-direction: row;
  }

  &.text-center {
    align-items: center;
  }
}

.MuiDialogTitle-root {
  line-height: 1em !important;
}

main {
  padding: 0px 5px !important;
}

.custom-card {
  box-shadow: none !important;

  .card-header {
    padding: 1em;
    background-color: $lightGrey;
  }
  .card-content {
    padding: 1em;
    background-color: $white;
    height: calc(100vh - 175px);
    overflow-y: auto;
  }
  .card-content_ {
    .table-container {
      height: calc(100vh - 175px);
    }
  }
}

.MuiToolbar-root {
  min-height: 50px !important;
}

.grid-cont {
  height: 500px;
  overflow: auto;
}

.custom-menu-list {
  max-height: 500px;
  overflow: auto;
  padding: 30px 0;

  .notification-card {
    box-shadow: none !important;
    cursor: pointer;
  }
}

.all-notifications {
  overflow: auto;
}

.heading {
  width: -webkit-fill-available;
  padding: 5px;
  margin: 0 10px !important;
  background-color: $white;
  position: fixed;
}

// User profile card
.custom-menu-item {
  margin: -5px 0px !important;
  cursor: default !important;
}

.custom-menu-item-card {
  &:focus {
    background-color: white !important;
  }
  background-color: white !important;
  padding: 5px 15px !important;
  // margin: -5px 0px !important;
  cursor: default !important;
  // border-bottom: 1px solid #e2e2e2 !important;
}

.custom-menu-item-card-all {
  &:hover {
    background-color: $white !important;
  }
  background-color: $white !important;
  padding: 0 !important;
  margin: 15px !important;
  // bottom: -10px;
  position: sticky;
}

.notification-card-outer {
  display: flex;
  width: 100%;
  padding: 10px;
}
.notification-card-inner {
  display: flex;
  flex-direction: row;
}

.row-message {
  margin: auto;
  line-height: 18px;
  li {
    font-size: 10px;
  }
}

.notification-icon {
  font-size: 28px !important;
  color: $tealGray;
  margin: 0 10px;
}

.grid-date {
  display: flex;

  p {
    font-size: 11px !important;
    color: grey !important;
  }
}

.menu-item-avatar {
  height: 70px !important;
  width: 70px !important ;
  color: #30465a !important;
  background-color: "#e9edf3" !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.logout-button {
  color: $red;
}
.logo {
  z-index: 2;
  position: fixed;
}
.appheader-icon {
  background-color: $white;
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.corium-icon {
  width: 100px;
  // cursor: pointer;
}

.profile-icon {
  height: 32px !important;
  width: 32px !important ;
  margin-left: -14px !important;
}

.content-footer {
  width: 100%;
  // height: 50px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  z-index: 9;
  border: 1px solid $lightGrey; // Use "border" instead of "borderBlock"
  padding: 8px;
}

.hierarchy-form-container {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 3;
  // border: 1px solid $lightGrey; // Use "border" instead of "borderBlock"
  // padding: 8px;
}

// Modal or Dialog box
.dialog-content {
  background-color: "#27aae1";
}

.custom-menu-item {
  &:hover {
    background-color: transparent !important;
  }
}

.custom-tree-view {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.custom-menu-item.jobtitle {
  font-weight: 300px;
  font-size: 15px;
}

.email-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// togle switch

.MuiTreeView-root {
  .MuiTreeItem-group {
    border-left: 1px dashed $grey !important;
  }

  .MuiTreeItem-content {
    padding: 6px 8px !important;
    border-radius: 0 15px 15px 0 !important;

    &.Mui-selected {
      background-color: $lightGrey !important;
      &:hover {
        background-color: $lightGrey !important;
      }
    }

    .MuiTreeItem-label {
      // font-weight: 500 !important;
      font-size: 11px !important;
      color: $dark;

      .custom-tree-node {
        .link {
          color: $lightblue !important;
          cursor: pointer;
        }
      }
    }
  }
}

.form-actions {
  position: sticky;
  bottom: -1.1em;
  background: $white;
}

.ag-theme-alpine.small {
  .ag-body .ag-body-viewport {
    min-height: 130px;
    overflow: auto;
  }
}
.territory-grid {
  .ag-body .ag-body-viewport {
    height: 100px;
  }
}
.ag-side-buttons {
  display: none;
}

.stats-card {
  display: flex;
  flex-direction: row;
  height: 175px;
  padding: 10px;
  align-items: flex-start;

  .stats-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .stats-title {
    font-size: 15px;
    font-weight: 600;
    margin: 0.75em;
    color: $primary;
  }
  .stats-count {
    font-size: 36px;
    font-weight: 600;
    margin: 0.5em 0;
    color: $primary;
  }

  .stats-records {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 1px;
    // font-weight: 600;
  }
  .records {
    font-weight: 600;
    &.right {
      text-align: right;
    }
    &.left {
      text-align: left;
    }
  }
  .stats-chip {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 10px;
  }
  .pst {
    color: green;
  }
  .ngt {
    color: red;
  }
}

.report-card-content {
  height: calc(100vh - 165px);
  overflow-y: auto;
  padding: 2em;

  .report-card {
    width: 100%;
    min-height: 175px;
    box-shadow: none;
    background: $white;
    border-radius: 16px;

    .card-icon {
      width: 30px;
    }

    .card-number {
      font-size: 20px;
      font-weight: 400;
      // color: $primary;
    }

    .card-title {
      font-size: 16px;
      font-weight: 600;
      margin: 0.75em 0;
      color: $primary;

      &.no-padding {
        margin: 0 !important;
      }
    }

    .card-split {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-stats {
        font-size: 14px;
        font-weight: 600;
        margin-right: 0.5em;
      }

      .card-chip {
        min-width: 50%;
        background: $lightGrey;
        border: none;
        font-weight: 600;
      }
    }
  }
}

.reports {
  padding: 10px;
}

.fixed-scroll {
  height: calc(100vh - 210px);
  overflow-y: auto;
}

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

.notification-chip {
  width: -webkit-fill-available;
  border-radius: 5px !important;
  font-weight: 600;
  padding: 5px;
  font-size: 10px;

  &.success {
    color: $success;
    background-color: $successBg;
  }
  &.warning {
    color: $warning;
    background-color: $warningBg;
  }
  &.failed {
    color: $failed;
    background-color: $failedBg;
  }
}

.notification-page {
  display: flex;
  padding: 10px 0;

  .notification-table {
    margin: 0 4px;
    width: 100%;

    .custom-card-notification {
      box-shadow: none !important;
      width: 100%;
      border-radius: 20px !important;

      .card-header-notification {
        background-color: $white;
      }
      .card-content_-notification {
        background-color: $white;
        height: calc(100vh - 130px);
        overflow-y: auto;
      }
    }
  }

  .notification-leftcard {
    background-color: $white;
    border-bottom: 1px solid $lightGrey;
    margin: 0 4px;

    .notification-sidebar-body {
      min-width: 310px;
      height: calc(100vh - 130px);
      overflow-y: auto;

      .report-card {
        box-shadow: none !important;
      }
      .notification-main {
        padding: 5px;
      }
    }

    .notification-card {
      background-color: $background !important;
      width: 100%;
      box-shadow: none !important;
      cursor: pointer;

      :hover {
        background: $lightGrey;
      }

      &.active {
        background-color: $darkGrey !important;
        :hover {
          background-color: $darkGrey !important;
        }
      }
    }
  }
}


.notification-dot {
  font-size: 11px !important;
  color: #27aae1;
  border-radius: 10px;
  margin-top: 7px;
  margin-right: -3px;
}

.notification-list {
  border-bottom: 1px solid $lightGrey;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.notification-typo {
  width: -webkit-fill-available;
  color: $primary;
  position: fixed;
  bottom: 0;
  cursor: pointer;
  text-align: center;
  padding: 10px 5px;
  background-color: $white;
}

.notification-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 25%;
  }

  h6 {
    color: $tealGray;
  }
}

.no-data-display {
  height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;

  img {
    width: 50%;
  }

  p {
    margin-top: 25px;
    color: $tealGray;
  }
}

.card-icons {
  font-size: 12px;
  margin-right: 5px;
  color: $tealGray;
}

.terminated {
  color: $red;
  font-weight: 600;
}

.admin-card-content {
  display: flex;

  .admin-options {
    padding: 7px 5px;
    border-right: 1px solid $lightGrey;
    min-width: 200px;
    text-align: left;
    background: $white;
    height: calc(100vh - 115px);
    overflow-y: auto;

    .accordion-details {
      background-color: $white;
      padding: 0 5px;
    }

    nav {
      padding-top: 5px;
      padding-left: 1em;
    }

    .nested-subheader {
      color: $primary;
      margin-bottom: 5px;
      line-height: 43px;
      font-size: 12px;
    }

    .option-name {
      margin: 0;
      padding: 3px 0;
      padding-left: 20px;
      transition:
        background-color 0.3s,
        color 0.3s;

      span {
        font-size: 11px;
        font-weight: 600;
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        background-color: #dee6ed;
        border-radius: 5px;
        border-right: 3px solid $primary;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .page-content {
    width: 100%;
    height: calc(100vh - 115px);
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 32px 0 #1264781c;

    .card-content_ {
      background-color: transparent !important;
      .search-box {
        display: flex;
        justify-content: space-between;
        padding: 13px;

        p {
          padding: 5px;
          font-size: 15px;
          color: $tealGray;
        }
      }
    }

    .custom-card {
      // height: 100%;
      background-color: white;
      height: calc(100vh - 115px);
      background-color: white;
      .table-container {
        height: 100%;
      }
    }
  }
}

.territory-levels {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 18px;
  font-weight: 500;
}

.notes {
  // padding: 0 10px;
  color: $blueGray;

  div,
  li {
    padding: 0;
  }
  .all-notes {
    margin: 0;
  }
  .note-textarea {
    padding: 10px;
    width: 100%;
    
  }
  .delete-btn {
    font-size: 11px;
    font-weight: 500;
    color: $blueGray;
    cursor: pointer;
    color: $red;
    max-width: fit-content;
  }
  .avatar {
    min-width: 45px;
    .MuiAvatar-root {
      background-color: $primary;
      color: $white;
      width: 32px;
      height: 32px;
      font-size: 13px;
    }
  }
  .notes-card {
    background-color: $white;
    box-shadow: none;
    margin: 20px 0;
  }
}

// Territory  module
.red-dot {
  color: $red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.existed-terr-alert{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
