$primary: #346393;
$dark: #152f51;
$grey: #e0e0e0;
$white: #ffffff;
$black: #000000;
$background: #f4f5f5;
$lightGrey: #e8edf3;
$darkGrey: #d5dbe2;
$red: #d32f2f;
$lightblue: #27aae1;
$tealGray: #6f8caa;
$success: #1E4620;
$successBg: #EDF7ED;
$warning: #663C00;
$warningBg: #FFF4E5;
$failed: #5F2120;
$failedBg: #FDEDED;
$error:#d32f2f;
$blueGray: #44546f;