.table-container {
  height: calc(100vh - 203px);
  width: 100%;
}
.table-assignment {
  width: calc(100vw - 615px);
}

.address-card {
  padding: 1em 1em 1em 0;
  border-radius: 4px;
  margin-bottom: 2em;
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #e8edf35e;
  position: relative;
}
.errors {
  padding: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 3px solid #da1e28;
  background-color: #fff1f1;
  box-sizing: border-box;
  display: flex;
}

.step-button {
  color: green !important;
}
.step-label {
  color: green !important;
}
.file-box {
  margin: 2px;
  margin-top: 2px;
  padding: 1px;
  border: 1px solid $lightGrey;
}

.import-file {
  width: 80%;
  margin-left: 10%;

  .tab-header {
    display: flex;
    justify-content: center;
  }

  .tab-content {
    margin-top: 1em;
    
    .template {
      text-align: center;
      margin-top: 3em;
    }
    .template-or {
      text-align: center;
      margin-top: 0.5em;
    }
    .template-text {
      font-size: 11px;
      text-decoration: none;
      background-color: #e8edf3;
      font-weight: 600;
      padding: 6px 12px;
      border-radius: 12px;
      margin-bottom: 1.5em;
    }

    .upload-content {
      padding: 2em 3em;
      margin-top: 1em;
      text-align: center;
      cursor: pointer;
      background: $lightGrey !important;
      border-radius: 12px !important;
    }

    
  }
}

.upload-card-content {
  width: 550px;
  height: calc(100vh - 350px);
  overflow-y: auto;
  padding-right: 5px;
}

.mapping-fields {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px;
}
.mapping-fields-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.menu-comp {
  display: flex;
  align-items: center;
  // margin: 0;
}

.import-chips {
  border-radius: 6px;
  margin: 1px;
}
.info-icon {
  font-size: 10px;
}

// Admin page
.delete-record{
  display: flex;
  justify-content: center;
  align-items: center;

}
.admin-delete-button{
  height: 40px;
  width: 40px;
  background-color: $error;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emp-startdate{
  display: inline-block;
  vertical-align: middle;
  color: $error;
}
.add-assignment-info{
  display: flex;
  justify-content: space-around;
  align-items: center;
}