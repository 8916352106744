.ag-theme-alpine {
  /* --ag-borders: none; */
  --ag-row-border-width: 1px;
  --ag-row-border-color: #e8edf3;

  --ag-header-height: 35px;
  --ag-border-color: #e8edf3;
  --ag-row-border-color: #e8edf3;

  --ag-header-background-color: #e8edf3;
  --ag-header-cell-hover-background-color: #e8edf3;
  --ag-header-cell-moving-background-color: #e8edf3;

  --ag-font-size: 10px;
  --ag-font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  --ag-grid-size: 4px;
  --ag-list-item-height: 40px;

  --ag-side-bar-panel-width: 250px;
}
